var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-cover" }, [
    _c("div", { staticClass: "Login base-height findemail" }, [
      _c("div", { staticClass: "login_left" }),
      _c("div", { staticClass: "mainBox" }, [
        _c(
          "h1",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/reviewmind/logo2.png"),
                  alt: "logo"
                }
              })
            ])
          ],
          1
        ),
        _c("div", { staticClass: "form login emailDone" }, [
          _c("div", { staticClass: "emailInfo" }, [
            _c("b", [_vm._v(_vm._s(_vm.email))]),
            _vm._v(" 회원님의 정보와 일치하는 이메일입니다. ")
          ]),
          _c("fieldset", [
            _c(
              "button",
              { staticClass: "btn full primary", attrs: { type: "button" } },
              [
                _c("router-link", { attrs: { to: "/login" } }, [
                  _vm._v(" 이메일로 로그인하기")
                ])
              ],
              1
            ),
            _c(
              "button",
              { staticClass: "btn full secondary", attrs: { type: "button" } },
              [
                _c("router-link", { attrs: { to: "/findPassword" } }, [
                  _vm._v(" 비밀번호찾기")
                ])
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }